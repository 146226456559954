import React from "react";
import { graphql } from "gatsby";
import { mapEdgesToNodes } from "../lib/helpers";
import Layout from "../components/common/layout";
import DocumentNavbar from "../components/document/document-navbar";
import VillageDataList from "../components/document/village-data-list";
import Pagination from "../components/common/pagination";

export const query = graphql`
  query VillageDataQuery($skip: Int!, $limit: Int!) {
    villageDatas: allSanityVillageData(
      skip: $skip
      limit: $limit
      sort: { order: ASC, fields: uploadedFile___caption }
    ) {
      edges {
        node {
          id
          author
          uploadedFile {
            caption
            asset {
              url
              originalFilename
            }
          }
        }
      }
    }
  }
`;

const VillageData = (props) => {
  const { data } = props;

  const nodes = (data || {}).villageDatas
    ? mapEdgesToNodes(data.villageDatas)
    : [];

  return (
    <Layout>
      <DocumentNavbar />
      {nodes && <VillageDataList nodes={nodes} />}
      <Pagination
        humanPageNumber={props.pageContext.humanPageNumber}
        numberOfPages={props.pageContext.numberOfPages}
        previousPagePath={props.pageContext.previousPagePath}
        nextPagePath={props.pageContext.nextPagePath}
        paginationPath={props.pageContext.paginationPath}
      />
    </Layout>
  );
};

export default VillageData;
